<template>
    <div class="users">
        <div class="users__header">
            <div id="users-header" class="users__left-side"></div>

            <div class="users__right-side">
                <CAction :actions="topActions" ignore-active-project-id/>
            </div>
        </div>

        <router-view @onSelect="selectedUsers = $event"/>
    </div>
</template>

<script>
import CAction from '@/components/CAction'
import Gate from '@/services/Gate/Gate'
import {notifyByPlan, permissionError} from '@/services/Notify/Toasts'
import {mapGetters} from "vuex";

export default {
    name: 'Index',
    components: {CAction},
    data() {
        return {
            selectedUsers: [],
            actions: [
                {
                    label: 'Send communication',
                    event: () => this.goToSendCommunication(),
                },
                {
                    label: 'Import Companies',
                    event: () => this.goToCompanyImport(),
                },
                {
                    label: 'Invite Company',
                    event: () => this.goToCompanyInvite(),
                },
                {
                    label: 'Invite Employee',
                    event: () => this.goToInviteEmployee(),
                },
            ]
        }
    },
    computed: {
        ...mapGetters([
            'isGeneralPlan'
        ]),
        topActions() {
            if (this.isGeneralPlan) {
                return this.actions;
            }

            return this.actions.filter(action => action.label === 'Invite Employee');
        },
    },
    methods: {
        async goToSendCommunication() {
            const check = await Gate.can('communication', 'employee')
            if (!check) {
                notifyByPlan()
                return false
            }
            this.$router.push({
                name: 'SendCommunication',
                query: {users: this.selectedUsers}
            })
        },
        async goToCompanyInvite() {
            const check = await Gate.can('create', 'company')
            if (!check) {
                notifyByPlan()
                return false
            }
            this.$router.push({name: 'CompanyInvite'})
        },
        async goToCompanyImport() {
            const check = await Gate.can('create', 'company')
            if (!check) {
                notifyByPlan()
                return false
            }
            this.$router.push({name: 'CompanyImport'})
        },
        async goToInviteEmployee() {
            const check = await Gate.can('create', 'employee')
            if (!check) {
                this.$notify(permissionError)
                return false
            }
            this.$router.push({name: 'InviteEmployee'})
        },
    },
}
</script>

<style lang="scss" scoped>
.users {
    padding-bottom: 20px;

    &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 20px;
    }
}
</style>
